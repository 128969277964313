
import { defineComponent, onMounted, reactive } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";

import SalesArea from "@/components/1crm/sales/sales-area/sales-area.vue";

export default defineComponent({
  name: "kt-widget-11",
  components: {
    SalesArea,
  },
  props: {
    widgetClasses: String,
  },

  beforeMount() {
    store.dispatch(Actions.VERIFY_AUTH_USER);
    setCurrentPageBreadcrumbs("Sales Area", ["Data"]);
  },

});
